@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Medium.woff2") format("woff2"),
    url("./assets/fonts/Gilroy-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

body {
  font-family: "Gilroy", sans-serif !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
