:root {
  --primary-color: #22b0a2;
  --primary-color-light: #f9c334;
  --secondary-color: #f9c824;
  --secondary-color-light: #f9c824;
  --third-color: #64c83d;
  --dark-color: #515151;
  --gray: #e6e6e6;
  --red: #dc3939;
  --gold: #ffedbd;
  --white: #ffff;
  --black: #000;
}

/* start landing */
.landing {
  padding-block: 2rem;
}

.landing .btn-primary {
  width: 100%;
  color: white;
  border-radius: 10px;
  padding: 10px;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.landing .btn-secondary {
  width: 100%;
  color: white;
  border-radius: 20px;
  padding: 10px;
  background-color: var(--primary-color-light);
  border: 1px solid var(--primary-color-light);
}

.landing .btn-secondary-fixed {
  width: 92%;
  color: white;
  margin-right: 4%;
  border-radius: 20px;
  padding: 10px;
  background-color: var(--primary-color-light);
  border: 1px solid var(--primary-color-light);
  position: fixed;
  z-index: 99;
  bottom: 0;
  right: 0;
}

/* .btn-goto-check-out {
  position: fixed;
  z-index: 99;
  background-color: white;
  color: #e4bb7f;
  font-size: 1.5em;
  font-weight: 700;
  border-radius: 15px;
  bottom: 0;
  right: 0;
  border: 2px solid #e4bb7f;
  margin-right: 4%;
  background-size: 100% 100%;
  width: 92%;
  height: 50px;
  margin-bottom: 2rem;
  align-content: end;
} */

.landing .box-benefit-landing {
  background-color: var(--primary-color);
  padding: 15px;
  height: 100%;
  font-size: 15px;
  border-radius: 10px;
  color: white;
}

.landing .card-event {
  padding: 0px;
  background-color: white;
  border-radius: 10px;
  justify-content: space-between;
  border: 0px;
  height: 100%;
  margin-right: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.landing .leaderboard-number-one {
  background-color: white;
  background: rgb(249, 195, 52);
  background: linear-gradient(
    180deg,
    rgba(249, 195, 52, 0.8169861694677871) 0%,
    rgba(249, 195, 52, 1) 100%
  );
  padding: 10px;
  border-radius: 10px;
}

.landing .box-affiliate-level-1 {
  margin-right: 15px;
  background: rgb(33, 176, 174);
  background: linear-gradient(
    180deg,
    rgba(33, 176, 174, 1) 0%,
    rgba(33, 176, 174, 0.40802258403361347) 100%
  );
  padding: 15px;
  border-radius: 15px;
  color: #089391;
  padding-bottom: 20px;
}

.landing .box-affiliate-level-2 {
  margin-right: 15px;
  background: rgb(0, 122, 255);
  background: linear-gradient(
    180deg,
    rgba(0, 122, 255, 1) 0%,
    rgba(0, 122, 255, 0.4976584383753502) 100%
  );
  padding: 15px;
  border-radius: 15px;
  color: #0055b0;
  padding-bottom: 20px;
}

.landing .box-affiliate-level-3 {
  margin-right: 15px;
  background: rgb(235, 138, 229);
  background: linear-gradient(
    180deg,
    rgba(235, 138, 229, 1) 0%,
    rgba(235, 138, 229, 0.5256696428571428) 100%
  );
  padding: 15px;
  border-radius: 15px;
  color: #a7489f;
  padding-bottom: 20px;
}

.landing .box-affiliate-level-4 {
  margin-right: 15px;
  background: rgb(207, 96, 32);
  background: linear-gradient(
    180deg,
    rgba(207, 96, 32, 1) 0%,
    rgba(255, 225, 155, 0.5032606792717087) 100%
  );
  padding: 15px;
  border-radius: 15px;
  color: #9b3600;
  padding-bottom: 20px;
}

.landing .box-affiliate-level-5 {
  margin-right: 15px;
  background: rgb(239, 185, 62);
  background: linear-gradient(
    180deg,
    rgba(239, 185, 62, 1) 0%,
    rgba(255, 225, 155, 1) 100%
  );
  padding: 15px;
  border-radius: 15px;
  color: #cb800f;
  padding-bottom: 20px;
}

/* end landing */

/* start register */

.register {
  padding-block: 3rem;
}

.register .btn-primary {
  width: 100%;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: white;
  padding: 15px;
  border-radius: 15px;
}

.register .btn-primary-google {
  width: 100%;
  background-color: var(--white);
  border: 0px;
  color: black;
  padding: 15px;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
/* end register */

/* start login */

.login {
  padding-block: 3rem;
}

.login .btn-primary {
  width: 100%;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: white;
  padding: 15px;
  border-radius: 15px;
}

.login .btn-primary-google {
  width: 100%;
  background-color: var(--white);
  border: 0px;
  color: black;
  padding: 15px;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
/* end login */

/* start home */

.home {
  padding: 2rem;
}

.home .box-balance {
  background-color: var(--primary-color);
  padding: 15px;
  border-radius: 15px;
  color: white;
  padding-bottom: 20px;
  margin-bottom: 1.5rem;
}

.home .card-event {
  padding: 0px;
  background-color: white;
  border-radius: 10px;
  height: 100%;
  border: 0px;
  margin-right: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.home .leaderboard-number-one {
  background-color: white;
  background: rgb(249, 195, 52);
  background: linear-gradient(
    180deg,
    rgba(249, 195, 52, 0.8169861694677871) 0%,
    rgba(249, 195, 52, 1) 100%
  );
  padding: 10px;
  border-radius: 10px;
}

.modal .btn-primary {
  width: 100%;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: white;
  padding: 10px;
  border-radius: 15px;
}

.home .btn-primary {
  width: 100%;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: white;
  padding: 10px;
  border-radius: 15px;
}

.home .share-event {
  width: 65%;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: white;
  padding: 5px;
  margin-top: -2rem;
  margin-bottom: 10px;
  border-radius: 10px;
}

.home .box-affiliate-level-1 {
  background: rgb(33, 176, 174);
  background: linear-gradient(
    180deg,
    rgba(33, 176, 174, 1) 0%,
    rgba(33, 176, 174, 0.40802258403361347) 100%
  );
  padding: 15px;
  border-radius: 15px;
  color: #089391;
  padding-bottom: 20px;
}

.home .box-affiliate-level-2 {
  background: rgb(0, 122, 255);
  background: linear-gradient(
    180deg,
    rgba(0, 122, 255, 1) 0%,
    rgba(0, 122, 255, 0.4976584383753502) 100%
  );
  padding: 15px;
  border-radius: 15px;
  color: #0055b0;
  padding-bottom: 20px;
}

.home .box-affiliate-level-3 {
  background: rgb(235, 138, 229);
  background: linear-gradient(
    180deg,
    rgba(235, 138, 229, 1) 0%,
    rgba(235, 138, 229, 0.5256696428571428) 100%
  );
  padding: 15px;
  border-radius: 15px;
  color: #a7489f;
  padding-bottom: 20px;
}

.home .box-affiliate-level-4 {
  background: rgb(207, 96, 32);
  background: linear-gradient(
    180deg,
    rgba(207, 96, 32, 1) 0%,
    rgba(255, 225, 155, 0.5032606792717087) 100%
  );
  padding: 15px;
  border-radius: 15px;
  color: #9b3600;
  padding-bottom: 20px;
}

.home .box-affiliate-level-5 {
  background: rgb(239, 185, 62);
  background: linear-gradient(
    180deg,
    rgba(239, 185, 62, 1) 0%,
    rgba(255, 225, 155, 1) 100%
  );
  padding: 15px;
  border-radius: 15px;
  color: #cb800f;
  padding-bottom: 20px;
}

.home .btn-primary-balance {
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--white);
  color: var(--primary-color);
  border-radius: 15px;
  padding: 10px;
}

.home .fixed-nav {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: var(--primary-color-light);
  border-bottom-left-radius: 50px;
  z-index: -1;
  border-bottom-right-radius: 50px;
  height: 11rem;
  width: 100%;
}

/* end home */

/* start not found */

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  font-family: "Arial", sans-serif;
}

.not-found-title {
  font-size: 10rem;
  margin: 0;
  color: var(--primary-color);
}

.not-found-text {
  font-size: 1.5rem;
  color: #2d3436;
  margin: 1rem 0;
}

.not-found-button {
  padding: 0.75rem 1.5rem;
  color: #fff;
  background-color: var(--primary-color);
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.not-found-button:hover {
  background-color: var(--primary-color);
}

.not-found-animation {
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 200px;
  margin-top: 2rem;
}

.not-found-cloud {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: moveClouds 15s linear infinite;
}

.not-found-cloud:nth-child(2) {
  width: 100px;
  height: 100px;
  top: 50px;
  left: 50%;
  animation-duration: 18s;
}

@keyframes moveClouds {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(200%);
  }
}

/* end not found */

/* Start media query */

/* Small */
@media (max-width: 768px) {
  .navbar {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }
}

/* medium */

@media (min-width: 768px) {
  .navbar {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }
}

@media (max-width: 992px) {
  .navbar {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }
}

/* Large */

@media (min-width: 1198px) {
  .navbar {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }
}
